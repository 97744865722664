import React, { Component } from "react";
import { GetUserLogin, GetOrderDetails } from "../../../../../services";
import { NotificationManager } from "react-notifications";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import * as apis from "../../../../../../../apis";
import "../../css/index.css";
export default class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: "",
      orderList: [],
    };
  }
  async componentDidMount() {
    let user_id = sessionStorage.getItem("us");
    if (user_id) {
      apis
        .getOrders(user_id)
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          this.setState({ orderList: data.result, isloaded: true });
        });
    } else {
      NotificationManager.error("Check your credential", "Login");
    }
  }
  handleLogout = async (event) => {
    event.preventDefault();
    await GetUserLogin.logout();
  };
  render() {
    let { user, orderList } = this.state;
    return (
      <div className="wrapper">
        <div className="gambo-Breadcrumb">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">Home</li>
                    <li className="breadcrumb-item active" aria-current="page">
                      My Orders
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <div className>
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-4">
                <div className="left-side-tabs">
                  <div className="dashboard-left-links">
                    <a href="/account/view" className="user-item">
                      <i className="uil uil-apps" />
                      Overview
                    </a>
                    <a href="/account/profile" className="user-item">
                      <i className="mdi mdi-account-outline" />
                      My profile
                    </a>
                    <a href="/account/order" className="user-item active">
                      <i className="uil uil-box" />
                      My Orders
                    </a>
                    <a className="user-item" onClick={this.handleLogout}>
                      <i className="uil uil-exit" />
                      Logout
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-9 col-md-8">
                <div className="dashboard-right">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="main-title-tab">
                        <h4>
                          <i className="uil uil-box" />
                          My Orders
                        </h4>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className="pdpt-bg">
                        <div className="pdpt-title">
                          <h6>Order List</h6>
                        </div>
                        <div className="order-body10">
                          <div class="card card-body account-right">
                            <div class="widget">
                              <div class="order-list-tabel-main table-responsive">
                                <table
                                  class="datatabel table table-striped table-bordered order-list-tabel"
                                  width="100%"
                                  cellspacing="0"
                                >
                                  <thead>
                                    <tr>
                                      <th>ORDER #</th>
                                      <th>ORDER DATE</th>
                                      <th>STATUS</th>
                                      <th>ORDER AMOUNT</th>
                                      <th>PAID AMOUNT</th>
                                      <th>BALANCE</th>
                                      <th>ACTION</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {orderList ? (
                                      orderList.map((row, index) => (
                                        <tr key={index}>
                                          <td>#{row.name}</td>
                                          <td>
                                            <Moment format="MM Do">
                                              {row.date_order}
                                            </Moment>
                                          </td>
                                          <td>
                                            {row.state === "processing" ? (
                                              <span className="badge badge-info">
                                                In Progress
                                              </span>
                                            ) : row.state === "cancel" ? (
                                              <span className="badge badge-danger">
                                                Canceled
                                              </span>
                                            ) : row.state === "shipping" ? (
                                              <span className="badge btn-primary">
                                                shipping
                                              </span>
                                            ) : row.state === "delieverd" ? (
                                              <span className="badge badge-success">
                                                Delivered
                                              </span>
                                            ) : (
                                              <span className="badge badge-warning">
                                                Delayed
                                              </span>
                                            )}
                                          </td>
                                          <td>{row.amount_total}</td>
                                          <td>{row["Payment Amount"]}</td>
                                          <td>{row["Remaining Payment"]}</td>
                                          <td>
                                            <Link
                                              className="btn btn-info btn-sm"
                                              to={{
                                                pathname:
                                                  "/account/order/details",
                                                query: row,
                                              }}
                                            >
                                              <i className="mdi mdi-eye"></i>
                                            </Link>
                                          </td>
                                        </tr>
                                      ))
                                    ) : (
                                      <p>Loading...</p>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
