import React, { Component } from "react";
import { GetUserLogin } from "../../../../services";
import "../css/index.css";
import { product } from "../../../../data";

export default class Wishlist extends Component {
  handleLogout = async (event) => {
    event.preventDefault();
    await GetUserLogin.logout();
  };
  render() {
    return (
      <div className="wrapper">
        <div className="gambo-Breadcrumb">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="index.html">Home</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      User Dashboard
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <div className>
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-4">
                <div className="left-side-tabs">
                  <div className="dashboard-left-links">
                    <a href="/account/view" className="user-item ">
                      <i className="uil uil-apps" />
                      Overview
                    </a>
                    <a href="/account/profile" className="user-item">
                      <i className="mdi mdi-account-outline" />
                      My profile
                    </a>
                    <a href="/account/order/list" className="user-item">
                      <i className="uil uil-box" />
                      My Orders
                    </a>
                    <a href="/account/rewards" className="user-item">
                      <i className="uil uil-gift" />
                      My Rewards
                    </a>
                    wwwwwwwwwwww
                    <a href="/account/address" className="user-item">
                      <i className="uil uil-location-point" />
                      My Address
                    </a>
                    <a className="user-item" onClick={this.handleLogout}>
                      <i className="uil uil-exit" />
                      Logout
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-9 col-md-8">
                <div className="dashboard-right">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="main-title-tab">
                        <h4>
                          <i className="uil uil-heart" />
                          Shopping Wishlist
                        </h4>
                      </div>
                    </div>
                    {product.map((item, index) => {
                      return (
                        <div className="col-lg-12 col-md-12">
                          <div className="pdpt-bg">
                            <div className="wishlist-body-dtt">
                              <div className="cart-item">
                                <div className="cart-product-img">
                                  <img src={`${item.image_128}`} alt="jowi" />
                                  <div className="offer-badge">4% OFF</div>
                                </div>
                                <div className="cart-text">
                                  <h4>{item.name}</h4>
                                  <div className="cart-item-price">
                                    KES. {item.Price.toLocaleSting()}{" "}
                                    <span>
                                      KES. {item.Price.toLocaleSting()}
                                    </span>
                                  </div>
                                  <button
                                    type="button"
                                    className="cart-close-btn"
                                  >
                                    <i className="uil uil-trash-alt" />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
