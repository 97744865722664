import React, { Component } from "react";
import * as apis from "../../../../../apis";
import { connect } from "react-redux";
import { addToCart } from "../../../../store/actions/cartActions";
import { NotificationManager } from "react-notifications";
import CircularProgress from "@material-ui/core/CircularProgress";
class Productview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      cList: [],
      categorybyproduct: [],
      isloaded: false,
      SearchKey: "",
      toggle: false,
      limit: 3,
    };
  }

  filteredData = () => {
    const { list } = this.state;
    let url = window.location.href.split("/");
    var SearchKey = url.pop() || url.pop();
    let filteredData =
      SearchKey === ""
        ? list
        : list.filter((newData) => {
            return (
              newData.name.toLowerCase().indexOf(SearchKey.toLowerCase()) !==
                -1 ||
              newData.CategoryName.toLowerCase().indexOf(
                SearchKey.toLowerCase()
              ) !== -1
            );
          });

    return filteredData;
  };
  async getFilterByProduct() {
    this.setState({ isloaded: true });

    try {
      let p = this.filteredData();
      if (p) {
        this.setState({ list: p, isloaded: true });
      }
    } catch (e) {
      NotificationManager.error("Empty data in category", "Data");
    }
  }

  async componentDidMount() {
    this.getFilterByProduct();
    window.scrollTo(0, 0);
    apis
      .getProduct()
      .then((res) => res.json())
      .then((data) => {
        this.setState({
          list: data.result,
          isloaded: true,
          cList: data.result,
        });
      });
  }
  componentWillReceiveProps() {
    this.getFilterByProduct();
  }
  async handleFilterCategory(row) {
    const { cList } = this.state;
    let category = cList.filter((item) => {
      return item.CategoryName === row;
    });
    if (category) {
      this.setState({
        list: category,
        isloaded: true,
        toggle: true,
      });
    } else {
      NotificationManager.error("empty data in category", "Undefined");
    }
  }
  onLoadMore = (event) => {
    this.setState({ limit: this.state.limit + 3 });
  };

  handleAddtoCart = (row) => {
    this.setState((prev) => ({ qty: prev.qty + 1 }));
    let newRow = { ...row, qty: this.state.qty };
    this.props.addToCart(newRow);
    this.setState({ qty: 1 });
  };
  render() {
    let { cList, toggle, list, isloaded } = this.state;
    let arr = cList.map((d) => {
      return d.CategoryName;
    });
    const Categories = arr.filter((x, i, a) => a.indexOf(x) === i);
    let url = window.location.href.split("/");
    var lastSegment = url.pop() || url.pop();
    const lowerSearchKey = lastSegment ? lastSegment.toLowerCase() : "";
    const filteredData =
      lowerSearchKey === "result" || toggle
        ? list
        : cList.filter((newData) => {
            return (
              newData.name
                .toLowerCase()
                .indexOf(lowerSearchKey.toLowerCase()) !== -1
            );
          });

    return (
      <div style={{ paddingTop: 50, }}>
        {/* All product */}
        <section className="shop-list section-padding">
          <div className="container">
            <div className="row">
              <div className="col-md-3">
                <div className="shop-filters">
                  <div id="accordion">
                    <div className="card">
                      <div className="card-header" id="headingOne">
                        <h5 className="mb-0">
                          <button
                            className="btn btn-link"
                            data-toggle="collapse"
                            data-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            All Category{" "}
                            <span className="mdi mdi-chevron-down float-right" />
                          </button>
                        </h5>
                      </div>
                      <div
                        id="collapseOne"
                        className="collapse show"
                        aria-labelledby="headingOne"
                        data-parent="#accordion"
                      >
                        <div className="card-body card-shop-filters">
                          {isloaded
                            ? Categories.map((row, index) => {
                                let nth = row.lastIndexOf("/");
                                return (
                                  <div className="card-body" key={index}>
                                    <div
                                      className="list-group bs-canvas-close"
                                      aria-label="Close"
                                      onClick={this.handleFilterCategory.bind(
                                        this,
                                        row
                                      )}
                                    >
                                      <span className="list-group-item list-group-item-action">
                                        {row.substring(nth + 1)}
                                      </span>
                                    </div>
                                  </div>
                                );
                              })
                            : ""}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-9">
                {!isloaded ? (
                  <div className="progress-bar-bk">
                    <CircularProgress color="secondary" />
                  </div>
                ) : (
                  <div className="row no-gutters">
                    {filteredData?.length > 0 ? (
                      filteredData.map((row, index) => {
                        return (
                          <div key={index} className="col-md-4">
                            <div className="item">
                              <div className="product">
                                <div className="product-header">
                                  <span className="badge badge-success"></span>

                                  <img
                                    className="img-fluid"
                                    src={row.Image}
                                    alt="product"
                                  />
                                  {row["Available Quantity"] >= 1 ? (
                                    <span className="veg text-success mdi mdi-circle" />
                                  ) : (
                                    <span className="veg text-danger mdi mdi-circle" />
                                  )}
                                </div>
                                <div className="product-body">
                                  <h5>{row.name}</h5>
                                  <h6>
                                    <strong>
                                      <span className="mdi mdi-approval" />{" "}
                                      Available in
                                    </strong>{" "}
                                    - {row.SKU}
                                  </h6>
                                </div>
                                {/* </Link> */}
                                <div className="product-footer">
                                  {row["Available Quantity"] > 0 ? (
                                    <button
                                      type="button"
                                      style={{ background: "#EF78AC" }}
                                      className="btn btn-secondary btn-sm float-right"
                                      onClick={() => this.handleAddtoCart(row)}
                                    >
                                      <i className="mdi mdi-cart-outline" /> Add
                                      To Cart
                                    </button>
                                  ) : (
                                    <p className=" btn-sm float-right">
                                      <div style={{ color: "red" }}>
                                        OUT OF STOCK
                                      </div>
                                    </p>
                                  )}
                                  <p className="offer-price mb-0">
                                    KES. {row.Price.toLocaleString()}{" "}
                                    <i className="mdi mdi-tag-outline" />
                                    <br />
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div  className="text-danger">
                        <h4 className="text-danger">Item not found in our product list !!!</h4>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>

        {/* end product section */}
      </div>
    );
  }
}
export default connect(null, { addToCart })(Productview);
