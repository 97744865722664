import React, { Component } from "react";
import { NotificationManager } from "react-notifications";
import "../css/index.css";

export default class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: "",
      user: "",
      firstName: "",
      lastName: "",
      phoneNo: "",
      email: "",
    };
  }
  handleChangeUser(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      user: {
        ...this.state.user,
        [name]: value,
      },
    });
  }
  // async componentDidMount() {
  //   let email = sessionStorage.getItem("email");
  //   if (email) {
  //     let value = await GetUserLogin.getCustomerDetail(email);
  //     if (value) {
  //       this.setState({ user: value.data });
  //     }
  //   }
  // }
  handleLogout = async (event) => {
    event.preventDefault();
    sessionStorage.clear();
    this.props.history.push(`/`);
  };

  render() {
    return (
      <div className="wrapper">
        <div className="gambo-Breadcrumb">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">Home</li>
                    <li className="breadcrumb-item active" aria-current="page">
                      My profile
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>

        <div className>
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-4">
                <div className="left-side-tabs">
                  <div className="dashboard-left-links">
                    <a href="/account/view" className="user-item">
                      <i className="uil uil-apps" />
                      Overview
                    </a>
                    <a href="/account/profile" className="user-item active">
                      <i className="mdi mdi-account-outline" />
                      My profile
                    </a>
                    <a href="/account/order/list" className="user-item">
                      <i className="uil uil-box" />
                      My Orders
                    </a>

                    <a className="user-item" onClick={this.handleLogout}>
                      <i className="uil uil-exit" />
                      Logout
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-9 col-md-8">
                <div className="dashboard-right card card-body account-right">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="main-title-tab">
                        <h4>
                          <i className="uil uil-box" />
                          My profile
                        </h4>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className="pdpt-bg">
                        <form>
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label className="control-label">
                                  User Name <span className="required"></span>
                                </label>
                                <input
                                  className="form-control border-form-control"
                                  type="text"
                                  name="firstName"
                                  disabled
                                  value={sessionStorage.getItem("user")}
                                  onChange={(e) => this.handleChangeUser(e)}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label className="control-label">
                                  Route Name <span className="required"></span>
                                </label>
                                <input
                                  className="form-control border-form-control"
                                  type="text"
                                  name="phone"
                                  disabled
                                  value={sessionStorage.getItem("route")}
                                  onChange={(e) => this.handleChangeUser(e)}
                                />
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
