import React, { Component } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Category from '../category'
export default class Bannerslider extends Component {
  render () {
    var settings = {
      dots: false,
      infinite: true,
      autoplay: true,
      speed: 2000,
      autoplaySpeed: 2000,
      slidesToShow: 1,
      slidesToScroll: 1
    }

    return (
      <div>
        <Category />
        {/* <Slider {...settings}> */}
          <div className='owl-item'>
            <img src='img/banners/Taimba1.jpg' alt='Taimba1'  />
          </div>
          {/* <div className='owl-item'>
            <img src='img/banners/Taimba2.jpg' alt='Taimba2'  />
          </div>
          <div className='owl-item'>
            <img src='img/banners/Taimba3.jpg' alt='Taimba3' />
          </div> */}
          {/* <div className='owl-item'>
            <img src='img/banners/Taimba4.jpg' alt='Taimba4' />
          </div> */}
          {/* <div className='owl-item'>
            <img src='img/banners/Taimba5.jpg' alt='Taimba5'  />
          </div> */}
        {/* </Slider> */}
      </div>
    )
  }
}
