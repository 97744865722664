


export const product = {
  jsonrpc: "2.0",
  id: null,
  result: [
    {
      id: 52,
      name: "Bananas (Grade A)",
      description: false,
      list_price: 430.0,
      image_128:
        "http://15.206.160.34:8069/web/image?model=product.product&id=52&field=image_128",
    },
    {
      id: 53,
      name: "Bananas (Grade B)",
      description: false,
      list_price: 380.0,
      image_128:
        "http://15.206.160.34:8069/web/image?model=product.product&id=53&field=image_128",
    },
    {
      id: 54,
      name: "Bananas (Grade C)",
      description: false,
      list_price: 320.0,
      image_128:
        "http://15.206.160.34:8069/web/image?model=product.product&id=54&field=image_128",
    },
    {
      id: 59,
      name: "Crates",
      description: false,
      list_price: 1.0,
      image_128:
        "http://15.206.160.34:8069/web/image?model=product.product&id=59&field=image_128",
    },
    {
      id: 89,
      name: "Watermelon",
      description: false,
      list_price: 37.0,
      image_128:
        "http://15.206.160.34:8069/web/image?model=product.product&id=89&field=image_128",
    },
    {
      id: 103,
      name: "Transport cost",
      description: false,
      list_price: 1.0,
      image_128:
        "http://15.206.160.34:8069/web/image?model=product.product&id=103&field=image_128",
    },
    {
      id: 134,
      name: "Garlic",
      description: false,
      list_price: 280.0,
      image_128:
        "http://15.206.160.34:8069/web/image?model=product.product&id=134&field=image_128",
    },
    {
      id: 137,
      name: "Ginger",
      description: false,
      list_price: 120.0,
      image_128:
        "http://15.206.160.34:8069/web/image?model=product.product&id=137&field=image_128",
    },
    {
      id: 139,
      name: "Packaging nets",
      description: false,
      list_price: 1.0,
      image_128:
        "http://15.206.160.34:8069/web/image?model=product.product&id=139&field=image_128",
    },
    {
      id: 146,
      name: "Potatoes (50kgs)",
      description: false,
      list_price: 900.0,
      image_128:
        "http://15.206.160.34:8069/web/image?model=product.product&id=146&field=image_128",
    },
    {
      id: 154,
      name: "Tecto",
      description: false,
      list_price: 1.0,
      image_128:
        "http://15.206.160.34:8069/web/image?model=product.product&id=154&field=image_128",
    },
    {
      id: 155,
      name: "Shipping Charges",
      description: false,
      list_price: 1.0,
      image_128:
        "http://15.206.160.34:8069/web/image?model=product.product&id=155&field=image_128",
    },
    {
      id: 156,
      name: "Banana (grade D)",
      description: false,
      list_price: 100.0,
      image_128:
        "http://15.206.160.34:8069/web/image?model=product.product&id=156&field=image_128",
    },
    {
      id: 160,
      name: "offloading potatoes",
      description: false,
      list_price: 1.0,
      image_128:
        "http://15.206.160.34:8069/web/image?model=product.product&id=160&field=image_128",
    },
    {
      id: 161,
      name: "fuel",
      description: false,
      list_price: 1.0,
      image_128:
        "http://15.206.160.34:8069/web/image?model=product.product&id=161&field=image_128",
    },
    {
      id: 162,
      name: "casuals",
      description: false,
      list_price: 1.0,
      image_128:
        "http://15.206.160.34:8069/web/image?model=product.product&id=162&field=image_128",
    },
    {
      id: 163,
      name: "sorting casuals",
      description: false,
      list_price: 1.0,
      image_128:
        "http://15.206.160.34:8069/web/image?model=product.product&id=163&field=image_128",
    },
    {
      id: 164,
      name: "driver",
      description: false,
      list_price: 1.0,
      image_128:
        "http://15.206.160.34:8069/web/image?model=product.product&id=164&field=image_128",
    },
    {
      id: 165,
      name: "cess",
      description: false,
      list_price: 1.0,
      image_128:
        "http://15.206.160.34:8069/web/image?model=product.product&id=165&field=image_128",
    },
    {
      id: 166,
      name: "water",
      description: false,
      list_price: 1.0,
      image_128:
        "http://15.206.160.34:8069/web/image?model=product.product&id=166&field=image_128",
    },
    {
      id: 168,
      name: "ethlene gas",
      description: false,
      list_price: 1.0,
      image_128:
        "http://15.206.160.34:8069/web/image?model=product.product&id=168&field=image_128",
    },
    {
      id: 188,
      name: "Nyayo beans(10KGS)",
      description: false,
      list_price: 950.0,
      image_128:
        "http://15.206.160.34:8069/web/image?model=product.product&id=188&field=image_128",
    },
    {
      id: 189,
      name: "Yellow beans(10kgs)",
      description: false,
      list_price: 850.0,
      image_128:
        "http://15.206.160.34:8069/web/image?model=product.product&id=189&field=image_128",
    },
    {
      id: 190,
      name: "Wairimu beans(10kgs)",
      description: false,
      list_price: 700.0,
      image_128:
        "http://15.206.160.34:8069/web/image?model=product.product&id=190&field=image_128",
    },
    {
      id: 191,
      name: "Kamande(10KGS)",
      description: false,
      list_price: 1850.0,
      image_128:
        "http://15.206.160.34:8069/web/image?model=product.product&id=191&field=image_128",
    },
    {
      id: 192,
      name: "Ndengu cotton(10KGS)",
      description: false,
      list_price: 1300.0,
      image_128:
        "http://15.206.160.34:8069/web/image?model=product.product&id=192&field=image_128",
    },
    {
      id: 194,
      name: "Njahi(10kgs)",
      description: false,
      list_price: 750.0,
      image_128:
        "http://15.206.160.34:8069/web/image?model=product.product&id=194&field=image_128",
    },
    {
      id: 198,
      name: "Tomatoes (20kgs crate)",
      description: false,
      list_price: 1200.0,
      image_128:
        "http://15.206.160.34:8069/web/image?model=product.product&id=198&field=image_128",
    },
    {
      id: 199,
      name: "Onion KE (10KG Net)",
      description: false,
      list_price: 600.0,
      image_128:
        "http://15.206.160.34:8069/web/image?model=product.product&id=199&field=image_128",
    },
    {
      id: 203,
      name: "banana processing",
      description: false,
      list_price: 1.0,
      image_128:
        "http://15.206.160.34:8069/web/image?model=product.product&id=203&field=image_128",
    },
    {
      id: 205,
      name: "Scouting",
      description: false,
      list_price: 1.0,
      image_128:
        "http://15.206.160.34:8069/web/image?model=product.product&id=205&field=image_128",
    },
    {
      id: 210,
      name: "Kapunga Aromati Rice (25kgs)",
      description: false,
      list_price: 1950.0,
      image_128:
        "http://15.206.160.34:8069/web/image?model=product.product&id=210&field=image_128",
    },
    {
      id: 213,
      name: "banana transport",
      description: false,
      list_price: 1.0,
      image_128:
        "http://15.206.160.34:8069/web/image?model=product.product&id=213&field=image_128",
    },
    {
      id: 214,
      name: "Mutembei",
      description: false,
      list_price: 1.0,
      image_128:
        "http://15.206.160.34:8069/web/image?model=product.product&id=214&field=image_128",
    },
    {
      id: 216,
      name: "Rosecoco(Beans)",
      description: false,
      list_price: 900.0,
      image_128:
        "http://15.206.160.34:8069/web/image?model=product.product&id=216&field=image_128",
    },
    {
      id: 217,
      name: "Electricity",
      description: false,
      list_price: 1.0,
      image_128:
        "http://15.206.160.34:8069/web/image?model=product.product&id=217&field=image_128",
    },
    {
      id: 218,
      name: "Groundnuts karanga(10kgs)",
      description: false,
      list_price: 1500.0,
      image_128:
        "http://15.206.160.34:8069/web/image?model=product.product&id=218&field=image_128",
    },
    {
      id: 219,
      name: "transport",
      description: false,
      list_price: 1.0,
      image_128:
        "http://15.206.160.34:8069/web/image?model=product.product&id=219&field=image_128",
    },
    {
      id: 220,
      name: "uber transport",
      description: false,
      list_price: 1.0,
      image_128:
        "http://15.206.160.34:8069/web/image?model=product.product&id=220&field=image_128",
    },
    {
      id: 222,
      name: "Warehouse table",
      description: false,
      list_price: 0.0,
      image_128:
        "http://15.206.160.34:8069/web/image?model=product.product&id=222&field=image_128",
    },
    {
      id: 223,
      name: "Pawpaw(15kgs crate)",
      description: false,
      list_price: 750.0,
      image_128:
        "http://15.206.160.34:8069/web/image?model=product.product&id=223&field=image_128",
    },
    {
      id: 225,
      name: "Pishori rice (10kgs bag)",
      description: false,
      list_price: 1250.0,
      image_128:
        "http://15.206.160.34:8069/web/image?model=product.product&id=225&field=image_128",
    },
    {
      id: 226,
      name: "July rent",
      description: false,
      list_price: 1.0,
      image_128:
        "http://15.206.160.34:8069/web/image?model=product.product&id=226&field=image_128",
    },
    {
      id: 228,
      name: "packagng bags",
      description: false,
      list_price: 1.0,
      image_128:
        "http://15.206.160.34:8069/web/image?model=product.product&id=228&field=image_128",
    },
    {
      id: 229,
      name: "Tomatoes sorting",
      description: false,
      list_price: 1.0,
      image_128:
        "http://15.206.160.34:8069/web/image?model=product.product&id=229&field=image_128",
    },
    {
      id: 230,
      name: "offoading & labelling watermelon",
      description: false,
      list_price: 1.0,
      image_128:
        "http://15.206.160.34:8069/web/image?model=product.product&id=230&field=image_128",
    },
    {
      id: 231,
      name: "offloading & labelling watermelon",
      description: false,
      list_price: 1.0,
      image_128:
        "http://15.206.160.34:8069/web/image?model=product.product&id=231&field=image_128",
    },
    {
      id: 232,
      name: "offfloading bananas",
      description: false,
      list_price: 1.0,
      image_128:
        "http://15.206.160.34:8069/web/image?model=product.product&id=232&field=image_128",
    },
    {
      id: 233,
      name: "Rent",
      description: false,
      list_price: 0.0,
      image_128:
        "http://15.206.160.34:8069/web/image?model=product.product&id=233&field=image_128",
    },
    {
      id: 234,
      name: "Sweet banana(15kgs crate)",
      description: false,
      list_price: 1200.0,
      image_128:
        "http://15.206.160.34:8069/web/image?model=product.product&id=234&field=image_128",
    },
    {
      id: 235,
      name: "Potatoes sorting",
      description: false,
      list_price: 1.0,
      image_128:
        "http://15.206.160.34:8069/web/image?model=product.product&id=235&field=image_128",
    },
    {
      id: 236,
      name: "Potatoes repackaging",
      description: false,
      list_price: 1.0,
      image_128:
        "http://15.206.160.34:8069/web/image?model=product.product&id=236&field=image_128",
    },
    {
      id: 237,
      name: "Potatoes repackaging casual pay",
      description: false,
      list_price: 1.0,
      image_128:
        "http://15.206.160.34:8069/web/image?model=product.product&id=237&field=image_128",
    },
    {
      id: 238,
      name: "labelling watermelon",
      description: false,
      list_price: 1.0,
      image_128:
        "http://15.206.160.34:8069/web/image?model=product.product&id=238&field=image_128",
    },
    {
      id: 239,
      name: "Matoke",
      description: false,
      list_price: 18.0,
      image_128:
        "http://15.206.160.34:8069/web/image?model=product.product&id=239&field=image_128",
    },
    {
      id: 243,
      name: "Offloading and sorting tomatoes",
      description: false,
      list_price: 1.0,
      image_128:
        "http://15.206.160.34:8069/web/image?model=product.product&id=243&field=image_128",
    },
    {
      id: 244,
      name: "watermelon  transport",
      description: false,
      list_price: 1.0,
      image_128:
        "http://15.206.160.34:8069/web/image?model=product.product&id=244&field=image_128",
    },
    {
      id: 245,
      name: "potatoes transport",
      description: false,
      list_price: 1.0,
      image_128:
        "http://15.206.160.34:8069/web/image?model=product.product&id=245&field=image_128",
    },
    {
      id: 246,
      name: "offloading tomatoes",
      description: false,
      list_price: 1.0,
      image_128:
        "http://15.206.160.34:8069/web/image?model=product.product&id=246&field=image_128",
    },
    {
      id: 247,
      name: "tomatoes transport",
      description: false,
      list_price: 1.0,
      image_128:
        "http://15.206.160.34:8069/web/image?model=product.product&id=247&field=image_128",
    },
    {
      id: 248,
      name: "Tomatoes transport and offloadig",
      description: false,
      list_price: 1.0,
      image_128:
        "http://15.206.160.34:8069/web/image?model=product.product&id=248&field=image_128",
    },
    {
      id: 249,
      name: "Consultancy",
      description: false,
      list_price: 1.0,
      image_128:
        "http://15.206.160.34:8069/web/image?model=product.product&id=249&field=image_128",
    },
    {
      id: 250,
      name: "Legal consultancy",
      description: false,
      list_price: 1.0,
      image_128:
        "http://15.206.160.34:8069/web/image?model=product.product&id=250&field=image_128",
    },
    {
      id: 251,
      name: "Annual returns",
      description: false,
      list_price: 1.0,
      image_128:
        "http://15.206.160.34:8069/web/image?model=product.product&id=251&field=image_128",
    },
    {
      id: 252,
      name: "potatoes repackaging bags",
      description: false,
      list_price: 1.0,
      image_128:
        "http://15.206.160.34:8069/web/image?model=product.product&id=252&field=image_128",
    },
  ],
};
