import React, { Component } from "react";
import { NotificationManager } from "react-notifications";
import * as apis from "../../../../../../apis";
const emailRegex = RegExp(
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
);

const formValid = ({ formErrors, ...rest }) => {
  let valid = true;

  // validate form errors being empty
  Object.values(formErrors).forEach((val) => {
    val.length > 0 && (valid = false);
  });

  // validate the form was filled out
  Object.values(rest).forEach((val) => {
    val === null && (valid = false);
  });

  return valid;
};
export default class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      firstName: null,
      lastName: null,
      phoneNumber: null,
      policy: null,
      routeID: null,
      password1: null,
      formErrors: {
        firstName: "",
        lastName: "",
        routeID: "",
        phoneNumber: "",
        password1: "",
        policy: "",
      },
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    apis
      .getRoutes()
      .then((res) => res.json())
      .then((data) => {
        this.setState({ routes: data.result, isloaded: true });
      });
  }

  handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formErrors = { ...this.state.formErrors };

    switch (name) {
      case "firstName":
        formErrors.firstName =
          value.length < 3 ? "minimum 3 characters required" : "";
        break;
      case "lastName":
        formErrors.lastName =
          value.length < 3 ? "minimum 3 characters required" : "";
        break;
      case "routeID":
        formErrors.routeID = value === null ? "A route is required" : "";
        break;
      case "PhoneNumber":
        formErrors.firstName =
          value.length < 3 ? "minimum 3 characters required" : "";
        break;
      case "password1":
        formErrors.password1 =
          value.length < 6 ? "minimum 6 characters required" : "";
        break;
      case "policy":
        formErrors.password1 =
          value.length < 6 ? "minimum 6 characters required" : "";
        break;
      default:
        break;
    }

    this.setState({ formErrors, [name]: value });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({ submitted: true });
    let { firstName, lastName, routeID, phoneNumber, password1, policy } =
      this.state;
    let data = {
      firstName: firstName,
      lastName: lastName,
      routeID: routeID,
      email: firstName + "_" + lastName,
      password: password1,
      phoneNumber: phoneNumber,
    };

    if (formValid(this.state) && policy) {
      apis
        .RegisterUser(data)
        .then((res) => res.json())
        .then((data) => {
          if (data.result.result === true) {
            NotificationManager.success("Successfully Added New User");
            window.location.href = "/login";
          } else {
            NotificationManager.error(data.result.message);
          }
        });
    } else {
      NotificationManager.error(
        "Please check your Registration details",
        "Input Error"
      );
    }
  };
  render() {
    let {
      firstName,
      email,
      lastName,
      routeID,
      routes,
      phoneNumber,
      password1,
      password2,
      formErrors,
      policy,
      submitted,
    } = this.state;
    console.log(formErrors);
    return (
      <div className="card checkout-step-one">
        <div className="card-header" id="headingOne">
          <h5 className="mb-0">
            <button
              className="btn btn-link"
              type="button"
              data-toggle="collapse"
              data-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              <span className="number">1</span> Login or SignUp
            </button>
          </h5>
        </div>
        <div
          id="collapseOne"
          className="collapse show"
          aria-labelledby="headingOne"
          data-parent="#accordionExample"
        >
          <div
            className="modal-dialog modal-lg modal-dialog-centered"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-body">
                <div className="login-modal login-page-bk">
                  <div className="row">
                    <div className="col-lg-6 pad-left-0">
                      <form
                        onSubmit={this.handleSubmit}
                        noValidate
                        style={{ justify: "center" }}
                      >
                        <div>
                          <h5 className="heading-design-h5">Register Now!</h5>
                          <fieldset className="form-group">
                            <label>First Name</label>
                            <input
                              type="text"
                              className="form-control"
                              name="firstName"
                              value={firstName}
                              onChange={this.handleChange}
                            />
                            {formErrors.firstName.length > 0 && (
                              <span className="errorMessage">
                                {formErrors.firstName}
                              </span>
                            )}
                          </fieldset>
                          <fieldset className="form-group">
                            <label>Last Name</label>
                            <input
                              type="text"
                              className="form-control"
                              name="lastName"
                              value={lastName}
                              onChange={this.handleChange}
                            />
                            {formErrors.lastName.length > 0 && (
                              <span className="errorMessage">
                                {formErrors.lastName}
                              </span>
                            )}
                          </fieldset>
                          <fieldset className="form-group">
                            <label>Phone Number</label>
                            <input
                              type="text"
                              className="form-control"
                              name="phoneNumber"
                              value={phoneNumber}
                              onChange={this.handleChange}
                            />
                            {formErrors.phoneNumber.length > 0 && (
                              <span className="errorMessage">
                                {formErrors.phoneNumber}
                              </span>
                            )}
                          </fieldset>
                          <fieldset className="form-group">
                            <label>Area of operation</label>
                            <select
                              className="form-control"
                              name="routeID"
                              value={routeID}
                              onChange={this.handleChange}
                            >
                              <option value="">
                                --Please choose an option--
                              </option>
                              {routes
                                ? routes.map((d) => {
                                    return (
                                      <option value={d.id}>{d.name}</option>
                                    );
                                  })
                                : ""}
                            </select>
                            {formErrors.routeID.length > 0 && (
                              <span className="errorMessage">
                                {formErrors.routeID}
                              </span>
                            )}
                          </fieldset>
                          <fieldset className="form-group">
                            <label>Enter Password</label>
                            <input
                              type="password"
                              className="form-control"
                              name="password1"
                              value={password1}
                              onChange={this.handleChange}
                            />
                            {formErrors.password1.length > 0 && (
                              <span className="errorMessage">
                                {formErrors.password1}
                              </span>
                            )}
                          </fieldset>
                          <fieldset className="form-group">
                            <label>Confirm Password</label>
                            <input
                              type="password"
                              className="form-control"
                              name="password2"
                              value={password2}
                              onChange={this.handleChange}
                            />
                            {password1 !== password2 && (
                              <span className="errorMessage">
                                Passwords do not match
                              </span>
                            )}
                          </fieldset>
                          <input
                            type="checkbox"
                            name="policy"
                            checked={policy}
                            onChange={(e) =>
                              this.setState({ policy: e.target.checked })
                            }
                          />{" "}
                          <label>Accept our terms and policy</label>
                          <p className="mb-0">
                            <a className="" href="/privacy-policy">
                              https://taimba.co.ke/privacy-policy/
                            </a>
                          </p>
                          {!policy && submitted && (
                            <span className="errorMessage">
                              Please accept our privacy policy
                            </span>
                          )}
                          <fieldset className="form-group">
                            <button
                              type="submit"
                              className="btn btn-lg btn-secondary btn-block"
                              onClick={this.handleSubmit}
                            >
                              Create Your Account
                            </button>
                          </fieldset>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
