import React, { Component } from "react";
import logo from "../Assets/logo.png";
export default class Footer extends Component {
  render() {
    return (
      <div>
        {/* Footer */}
        <section className="section-padding bg-white border-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-sm-6">
                <div className="feature-box">
                  <i className="mdi mdi-truck-fast" />
                  <h6>Free &amp; Next Day Delivery</h6>
                  {/* <p>Lorem ipsum dolor sit amet, cons...</p> */}
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="feature-box">
                  <i className="mdi mdi-basket" />
                  <h6>100% Satisfaction Guarantee</h6>
                  {/* <p>Rorem Ipsum Dolor sit amet, cons...</p> */}
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="feature-box">
                  <i className="mdi mdi-tag-heart" />
                  <h6>Great Daily Deals </h6>
                  {/* <p>Sorem Ipsum Dolor sit amet, Cons...</p> */}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          style={{ alignContent: "center" }}
          className="section-padding footer bg-white border-top"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-3">
                <h4 className="mb-5 mt-0">
                  <a className="logo" href="index.html">
                    <img src={logo} alt="Citi" />
                  </a>
                </h4>
                <p className="mb-0">
                  <a className="text-dark" href="#">
                    <i className="mdi mdi-phone" /> +254 709 790000
                  </a>
                </p>
                <p className="mb-0">
                  <a className="text-dark" href="#">
                    <i className="mdi mdi-cellphone-iphone" /> +254 0709 790000
                  </a>
                </p>
              </div>
              <div className="col-lg-2 col-md-2">
                <h6 className="mb-4">AREAS WE SERVE </h6>
                <ul>
                  <li>
                    <a href="#">Ngara</a>
                  </li>
                  <li>
                    <a href="#">Kasarani</a>
                  </li>
                  <li>
                    <a href="#">Babadogo</a>
                  </li>
                  <li>
                    <a href="#">Umoja and Donholm</a>
                  </li>
                  <li>
                    <a href="#">Embakasi</a>
                  </li>
                  <ul></ul>
                </ul>
              </div>

              <div className="col-lg-2 col-md-2">
                <h6 className="mb-4">ABOUT US</h6>
                <ul>
                  <li>
                    <a href="https://taimba.co.ke/">Taimba Limited</a>
                  </li>
                </ul>
              </div>
              <div>
                <h6 className="mb-3 mt-4">GET IN TOUCH</h6>
                <div className="footer-social">
                  <a className="btn-facebook" href="facebook.com/taimba">
                    <i className="mdi mdi-facebook" />
                  </a>
                  <a className="btn-twitter" href="#">
                    <i className="mdi mdi-twitter" />
                  </a>
                  <a className="btn-instagram" href="#">
                    <i className="mdi mdi-instagram" />
                  </a>
                  <a className="btn-whatsapp" href="#">
                    <i className="mdi mdi-whatsapp" />
                  </a>
                  <a className="btn-messenger" href="#">
                    <i className="mdi mdi-facebook-messenger" />
                  </a>
                  <a className="btn-google" href="#">
                    <i className="mdi mdi-google" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End Footer */}
        {/* Copyright */}
        <section className="pt-4 pb-4 footer-bottom">
          <div className="container">
            <div className="row no-gutters">
              <div className="col-lg-6 col-sm-6">
                <p className="mt-1 mb-0">
                  © Copyright 2020{" "}
                  <strong className="text-dark">
                    Baba Dogo, Industrial park
                  </strong>
                  . All Rights Reserved
                  <br />
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* End Copyright */}
      </div>
    );
  }
}
