import React, { Component } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { addToCart } from "../../../../../store/actions/cartActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import { NotificationManager } from "react-notifications";

class Topsavers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isloaded: true,
      qty: 1,
    };
  }

  handleAddtoCart = (row) => {
    const cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];

    let alreadyExists = false;
    if (row["Available Quantity"] > 0) {
      if (!alreadyExists) {
        cartItems.forEach((x) => {
          if (x.id === row.id) {
            alreadyExists = true;
            NotificationManager.info(`${x.name} already added to cart`);
          }
        });
        if (!alreadyExists) {
          this.setState((prev) => ({ qty: prev.qty + 1 }));
          let newRow = { ...row, qty: this.state.qty };
          this.props.addToCart(newRow);
          this.setState({ qty: 1 });
          NotificationManager.success(`${row.name} succesfully added to cart`);
        }
      }
    } else {
      NotificationManager.error(`${row.name} is out of stock`);
    }
  };
  render() {
    const { list, cList, isloaded, handleFilterCategory } = this.props;
    let arr = cList.map((d) => {
      return d.CategoryName;
    });

    const Categories = arr.filter((x, i, a) => a.indexOf(x) === i);
    return (
      <section className="shop-list section-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <div className="shop-filters">
                <div id="accordion">
                  <div className="card">
                    <div className="card-header" id="headingOne">
                      <h5 className="mb-0">
                        <button
                          className="btn btn-link"
                          data-toggle="collapse"
                          data-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          All Category{" "}
                          <span className="mdi mdi-chevron-down float-right" />
                        </button>
                      </h5>
                    </div>
                    <div
                      id="collapseOne"
                      className="collapse show"
                      aria-labelledby="headingOne"
                      data-parent="#accordion"
                    >
                      <div className="card-body card-shop-filters">
                        {isloaded
                          ? Categories.map((row, index) => {
                              let nth = row.lastIndexOf("/");
                              return (
                                <div className="card-body" key={index}>
                                  <div
                                    className="list-group bs-canvas-close"
                                    aria-label="Close"
                                    onClick={() => handleFilterCategory(row)}
                                  >
                                    <span className="list-group-item list-group-item-action">
                                      {row.substring(nth + 1)}
                                    </span>
                                  </div>
                                </div>
                              );
                            })
                          : ""}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-9">
              {!isloaded ? (
                <div className="progress-bar-bk">
                  <CircularProgress color="secondary" />
                </div>
              ) : (
                <div className="row no-gutters">
                  {list ? (
                    list.map((row, index) => {
                      return (
                        <div key={index} className="col-md-4">
                          <div className="item">
                            <div className="product">
                              <div className="product-header">
                                <span className="badge badge-success"></span>

                                <img
                                  className="img-fluid"
                                  src={row.Image}
                                  alt="product"
                                />
                                {row["Available Quantity"] >= 1 ? (
                                  <span className="veg text-success mdi mdi-circle" />
                                ) : (
                                  <span className="veg text-danger mdi mdi-circle" />
                                )}
                              </div>
                              <div className="product-body">
                                <h5>{row.name}</h5>
                                <h6>
                                  <strong>
                                    <span className="mdi mdi-approval" />{" "}
                                    Available in
                                  </strong>{" "}
                                  - {row.SKU}
                                </h6>
                              </div>
                              {/* </Link> */}
                              <div className="product-footer">
                                {row["Available Quantity"] > 0 ? (
                                  <button
                                    type="button"
                                    style={{ background: "#EF78AC" }}
                                    className="btn btn-secondary btn-sm float-right"
                                    onClick={() => this.handleAddtoCart(row)}
                                  >
                                    <i className="mdi mdi-cart-outline" /> Add
                                    To Cart
                                  </button>
                                ) : (
                                  <p className=" btn-sm float-right">
                                    <div style={{ color: "red" }}>
                                      OUT OF STOCK
                                    </div>
                                  </p>
                                )}
                                <p className="offer-price mb-0">
                                  KES. {row.Price.toLocaleString()}{" "}
                                  <i className="mdi mdi-tag-outline" />
                                  <br />
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="text-danger">
                      Empty item in this category
                    </div>
                  )}
                </div>
              )}

              {/* <div class="more-product-btn">
                <button
                  style={{ background: "#EF78AC" }}
                  class="show-more-btn hover-btn"
                  onClick={this.onLoadMore}
                >
                  Show More
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default connect(null, { addToCart })(Topsavers);
