import React, { Component } from "react";
import { NotificationManager } from "react-notifications";
import Register from "../register";
import * as apis from "../../../apis";

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: null,
      password: null,
      errorMesg: null,
      successMesg: null,
      formErrors: {
        email: "",
        password: "",
      },
    };
  }
  handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formErrors = { ...this.state.formErrors };

    switch (name) {
      case "email":
        formErrors.email =
          value.length < 0 ? "Please enter valid phone number or email" : "";
        break;
      case "password":
        formErrors.password =
          value.length < 6 ? "minimum 6 characters required" : "";
        break;
      default:
        break;
    }

    this.setState({ formErrors, [name]: value });
  };

  authenticateByCart = async (
    token,
    user_id,
    user_route,
    user_name,
    route_name
  ) => {
    if (typeof window !== "undefined") {
      sessionStorage.setItem("_sid", token);
      sessionStorage.setItem("us", user_id);
      sessionStorage.setItem("rt", user_route);
      sessionStorage.setItem("user", user_name);
      sessionStorage.setItem("route", route_name);
      window.location.href = "/";
    } else {
      NotificationManager.error("Please check your login", "Input Error");
    }
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    let { email, password, resetPassword } = this.state;
    if (!resetPassword) {
      // if (formValid(this.state)) {
      apis
        .UserLogin(email, password)
        .then((res) => res.json())
        .then((data) => {
          if (data.result.result !== false) {
            this.setState({ successMesg: "Login successful", errorMesg: null });
            this.authenticateByCart(
              data.result.user_token,
              data.result.user_id,
              data.result.user_route,
              data.result.user_name,
              data.result.route_name
            );
          } else {
            this.setState({
              errorMesg: data.result.message,
            });
          }
        });
    } else {
      apis
        .resetPassWord(email, password)
        .then((res) => res.json())
        .then((data) => {
          if (data.result.result === true) {
            this.setState({ successMesg: data.result.message });
            this.setState({ resetPassword: false });
          } else {
            this.setState({ errorMesg: data.result.message });
          }
        });
    }
    setTimeout(() => {
      this.setState({ errorMesg: null, successMesg: null });
    }, 6000);
  };
  render() {
    let {
      email,
      password,
      password1,
      successMesg,
      resetPassword,
      formErrors,
      errorMesg,
    } = this.state;
    return (
      <div>
        <div className="modal fade login-modal-main" id="bd-example-modal">
          <div
            className="modal-dialog  modal-dialog-centered"
            // role="document"
          >
            <div className="modal-content">
              <div className="modal-body">
                <div className="login-modal">
                  <div className="row">
                    <div >
                      <button
                        type="button"
                        className="close close-top-right"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">
                          <i className="mdi mdi-close" />
                        </span>
                        <span className="sr-only">Close</span>
                      </button>
                      <form onSubmit={this.handleSubmit} noValidate>
                        <div className="login-modal-right">
                          {/* Tab panes */}
                          <div className="tab-content">
                            <div
                              className="tab-pane active"
                              id="login"
                              role="tabpanel"
                            >
                              <h5 className="heading-design-h5">
                                {resetPassword
                                  ? "Reset your credentials"
                                  : "Login to your account"}
                              </h5>
                              {!resetPassword && (
                                <div>
                                  <fieldset className="form-group">
                                    <label>Enter Email/Mobile number</label>
                                    <input
                                      type="email"
                                      className="form-control"
                                      name="email"
                                      value={email}
                                      onChange={this.handleChange}
                                    />
                                    {formErrors.email.length > 0 && (
                                      <span className="errorMessage">
                                        {formErrors.email}
                                      </span>
                                    )}
                                  </fieldset>
                                  <fieldset className="form-group">
                                    <label>Enter Password</label>
                                    <input
                                      className="form-control"
                                      name="password"
                                      type="password"
                                      value={password}
                                      onChange={this.handleChange}
                                    />
                                    {formErrors.password.length > 0 && (
                                      <span className="errorMessage">
                                        {formErrors.password}
                                      </span>
                                    )}
                                  </fieldset>
                                </div>
                              )}
                              {resetPassword && (
                                <div>
                                  <fieldset className="form-group">
                                    <label>Enter Email/Mobile number</label>
                                    <input
                                      type="email"
                                      className="form-control"
                                      name="email"
                                      value={email}
                                      onChange={this.handleChange}
                                    />
                                    {formErrors.email.length > 0 && (
                                      <span className="errorMessage">
                                        {formErrors.email}
                                      </span>
                                    )}
                                  </fieldset>
                                  <fieldset className="form-group">
                                    <label>Enter new Password</label>
                                    <input
                                      className="form-control"
                                      name="password"
                                      type="password"
                                      value={password}
                                      onChange={this.handleChange}
                                    />
                                    {formErrors.password.length > 0 && (
                                      <span className="errorMessage">
                                        {formErrors.password}
                                      </span>
                                    )}
                                  </fieldset>
                                  <fieldset className="form-group">
                                    <label>Confirm Password</label>
                                    <input
                                      className="form-control"
                                      name="password1"
                                      type="password"
                                      value={password1}
                                      onChange={this.handleChange}
                                    />
                                    {password1 !== password && (
                                      <span className="errorMessage">
                                        Password do not match
                                      </span>
                                    )}
                                  </fieldset>
                                </div>
                              )}
                              <fieldset className="form-group">
                                <button
                                  style={{ background: "#EF78AC" }}
                                  type="submit"
                                  className="btn btn-lg btn-secondary btn-block"
                                  onClick={this.handleSubmit}
                                >
                                  {resetPassword
                                    ? "Reset Password"
                                    : "Enter to your account"}
                                </button>
                              </fieldset>
                              {errorMesg && (
                                <div style={{ color: "red" }}>{errorMesg}</div>
                              )}
                              {successMesg && (
                                <div style={{ color: "green" }}>
                                  {successMesg}
                                </div>
                              )}
                              <div className="custom-control custom-checkbox"></div>
                            </div>
                          </div>
                          <div className="clearfix" />
                          <div className="text-center login-footer-tab">
                            <ul className="nav nav-tabs" role="tablist">
                              <li className="nav-item">
                                <a
                                  className="nav-link active"
                                  data-toggle="tab"
                                  href="#login"
                                  onClick={() =>
                                    this.setState({
                                      resetPassword: !resetPassword,
                                    })
                                  }
                                  role="tab"
                                >
                                  <i className="" />
                                  {resetPassword ? "LOGIN" : "NEW PASSWORD?"}
                                </a>
                              </li>
                              <li className="nav-item">
                                <a className="nav-link" href="/register">
                                  <i className="mdi mdi-pencil" /> REGISTER
                                </a>
                              </li>
                            </ul>
                          </div>
                          <div className="clearfix" />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
