import React, { Component } from "react";
import Login from "../../auth/login";
import { withRouter } from "react-router-dom";
import Cartsidebar from "../web/views/cart-sidebar";
import logo from "../Assets/logo.png";
import * as apis from "../../../apis";

class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: "",
      userName: "",
      searchtxt: "",
      product: [],
    };
  }

  updateLocalStorage = () => {
    const { product } = this.state;
    const cartItems = JSON.parse(localStorage.getItem("cartItems"));
    product.forEach((element) => {
      if (cartItems) {
        const selectProduct = cartItems?.find((item) => item.id === element.id);
        const index = cartItems.indexOf(selectProduct);
        const value = cartItems[index];
        if (selectProduct && value.qty !== 0) {
          value.Price = element.Price;
          value.total = value.qty * element.Price;
        }
        localStorage.setItem("cartItems", JSON.stringify(cartItems));
      }
    });
  };

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  async componentDidMount() {
    this.setState({
      userName: sessionStorage.getItem("user"),
      token: sessionStorage.getItem("tk"),
    });
    apis
      .getProduct()
      .then((res) => res.json())
      .then((data) => {
        this.setState({
          product: data.result,
        });
      });
    this.updateLocalStorage();
  }
  handleLogout = async (event) => {
    sessionStorage.clear();
    this.props.history.push(`/`);
  };
  handleClickSearch = (event) => {
    let { searchtxt } = this.state;
    this.props.history.push(`/product/catalogsearch/result/${searchtxt}`);
  };
  render() {
    this.updateLocalStorage();
    const { searchtxt } = this.state;
    const userName = sessionStorage.getItem("user");
    return (
      <div>
        <header className="header clearfix">
          <nav className="navbar navbar-light navbar-expand-lg  bg-faded osahan-menu">
            <div className="container-fluid" style={{ background: "#9BCC67" }}>
              <a className="navbar-brand" href="/">
                <img src={logo} alt="logo" width="70" />
              </a>
              <div className="navbar-collapse" id="navbarNavDropdown">
                <div className="navbar-nav mr-auto mt-2 mt-lg-0 margin-auto top-categories-search-main">
                  <div
                    className="top-categories-search"
                    onSubmit={this.handleClickSearch}
                  >
                    <div className="input-group">
                      <input
                        className="form-control"
                        placeholder="Search products in your area"
                        aria-label="Search products in Your area"
                        type="text"
                        name="searchtxt"
                        value={searchtxt}
                        onChange={(e) => this.handleChange(e)}
                      />
                      <span className="input-group-btn">
                        <div style={{ color: "white", background: "#EF78AC" }}>
                          <button
                            style={{ color: "white" }}
                            className="btn "
                            type="submit"
                            onClick={this.handleClickSearch}
                          >
                            <i className="mdi mdi-file-find" /> Search
                          </button>
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="my-2 my-lg-0">
                  <ul className="list-inline main-nav-right">
                    <li className="list-inline-item">
                      {!userName ? (
                        <button
                          data-target="#bd-example-modal"
                          data-toggle="modal"
                          style={{
                            backgroundColor: "#9BCC67" /* Green */,
                            border: "none",
                            color: "white",
                            padding: "15px 32px",
                            textAlign: "center",
                            textDecoration: "none",
                            display: "inline-block",
                            fontSize: "16px",
                          }}
                          // className="btn btn-link"
                          // style={
                          //   userName ? { display: "none" } : { display: "block" }
                          // }
                        >
                          Login/Sign Up
                        </button>
                      ) : (
                        <div
                          className="dropdown"
                          style={
                            userName
                              ? { display: "block" }
                              : { display: "none" }
                          }
                        >
                          <button
                            style={{
                              backgroundColor: "#9BCC67" /* Green */,
                              border: "none",
                              color: "white",
                              padding: "15px 32px",
                              textAlign: "center",
                              textDecoration: "none",
                              display: "inline-block",
                              fontSize: "16px",
                            }}
                            className=" dropdown-toggle"
                            type="button"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            {userName}
                          </button>

                          <div
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton"
                          >
                            <a className="dropdown-item" href="/account/view">
                              <i className="uil uil-apps" />
                              Dashboard
                            </a>
                            <a
                              className="dropdown-item"
                              href="/account/profile"
                            >
                              <i
                                className="mdi mdi-account-outline"
                                aria-hidden="true"
                              ></i>
                              My Profile
                            </a>

                            <a
                              className="dropdown-item"
                              href="/account/order/list"
                            >
                              <i
                                className="mdi mdi-format-list-bulleted"
                                aria-hidden="true"
                              ></i>{" "}
                              Orders List
                            </a>
                            <div class="dropdown-divider"></div>
                            <span
                              className="dropdown-item"
                              onClick={this.handleLogout}
                            >
                              <i
                                className="mdi mdi-lock"
                                aria-hidden="true"
                              ></i>{" "}
                              Logout
                            </span>
                          </div>
                        </div>
                      )}
                    </li>
                    <li className="list-inline-item cart-btn">
                      <Cartsidebar />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </header>
        {/* login popup */}
        <Login />
      </div>
    );
  }
}
export default withRouter(Navigation);
