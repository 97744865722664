import React, { Component } from "react";
import Bannerslider from "../banner-carousel";
import Topsavers from "./top-section";
// import Bestofferbanner from "./best-offers-banner";
// import Topstample from "./top-stample";
import * as apis from "../../../../../apis";
import { NotificationManager } from "react-notifications";

export default class Home extends Component {
  state = {
    cList: [],
    list: [],
    isloaded: false,
  };
  componentDidMount() {
    apis
      .getProduct()
      .then((res) => res.json())
      .then((data) => {
        const arr = data.result
          .map((a) => ({ sort: Math.random(), value: a }))
          .sort((a, b) => a.sort - b.sort)
          .map((a) => a.value);
        this.setState({
          list: arr,
          cList: arr,
          isloaded: true,
        });
      });
  }

  handleFilterCategory = (row) => {
    const { cList } = this.state;
    let category = cList.filter((item) => {
      return item.CategoryName === row;
    });
    if (category) {
      this.setState({
        list: category,
        isloaded: true,
        toggle: true,
      });
    } else {
      NotificationManager.error("empty data in category", "Undefined");
    }
  };

  render() {
    const { list, cList, isloaded } = this.state;
    return (
      <div className="wrapper">
        <div>
          <Bannerslider />
          <div style={{ zIndex: 50 }}>
            <Topsavers
              list={list}
              cList={cList}
              isloaded={isloaded}
              handleFilterCategory={this.handleFilterCategory}
            />
          </div>
        </div>
        {/* <Bestofferbanner product={product} /> */}
        {/* <Topstample product={product} /> */}
      </div>
    );
  }
}
