import React, { Component } from "react";
import { connect } from "react-redux";
import * as api from "../../../../../apis";
import { NotificationManager } from "react-notifications";
import { GetOrderDetails, CartHelper } from "../../../services";
import {
  removeFromCart,
  incrementToCart,
  decreaseToCart,
} from "../../../../store/actions/cartActions";
import Loader from "../../../../loader";
class Checkout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      subTotal: "",
      discount: "",
      deliveryCharge: 0,
      grandTotal: "",
      email: "",
      customer: "",
      paymentmethod: "",
      deliveryAddress: "",
    };
  }

  handleRadioChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleDeliveryAddress = (value) => {
    this.setState({ deliveryAddress: value });
  };

  async componentDidMount() {
    let cart = this.props.cartItems;
    let subTotal = cart.reduce((sum, i) => (sum += i.qty * i.Price), 0);
    let grandTotal = subTotal + this.state.deliveryCharge;

    this.setState({
      subTotal: subTotal,
      grandTotal: grandTotal,
      deliveryCharge: this.state.deliveryCharge,
    });
  }
  handlePlaceOrder = async (event) => {
    event.preventDefault();
    const { grandTotal, deliveryAddress, paymentmethod } = this.state;
    let orderId = Math.floor(
      Math.random() * Math.floor(Math.random() * Date.now())
    );
    let { cartItems } = this.props;
    let ProductList = cartItems.map((d) => {
      let newProductList = [];
      return {
        ...newProductList,
        product_id: d.id,
        product_uom_qty: d.qty,
        price_subtotal: d.qty * d.Price,
      };
    });

    let data = {
      paymentmethod: paymentmethod,
      orderId: orderId,
      deliveryAddress: deliveryAddress,
      product: ProductList,
      grandTotal,
    };
    if (data) {
      api
        .newCreatOrderProduct(data)
        .then((res) => res.json())
        .then((data) => {
          if ((data.result.result = true)) {
            NotificationManager.success("Successfully Ordered", "Order");
            setTimeout(async function () {
              CartHelper.emptyCart();
              window.location.href = "/";
            }, 1000);
          } else {
            NotificationManager.error("Order is declined", "Order");
            setTimeout(async function () {
              window.location.href = "/failed";
            }, 1000);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  handlePaymentSystem = async (e) => {
    e.preventDefault();
    const { customer, grandTotal, deliveryAddress } = this.state;
    let { cartItems } = this.props;
    let orderId = Math.floor(
      Math.random() * Math.floor(Math.random() * Date.now())
    );
    this.setState({ isLoaded: true });
    console.log("deliveryAddress", deliveryAddress);
    if (deliveryAddress) {
      //payment system
      const res = await this.loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );

      if (!res) {
        alert("Razorpay SDK failed to load. Are you online?");
        return;
      }

      //creating new order
      let data1 = {
        amount: grandTotal,
        order_id: orderId,
        currency: "INR",
        payment_capture: 1,
      };
      const result = await GetOrderDetails.getPaymentValue(data1);
      if (!result.data) {
        alert("Server error. Are you online?");
        return;
      } else {
        const __DEV__ = document.domain === "localhost";
        var options = {
          key: __DEV__ ? "rzp_test_OkYZMYKswptVZX" : "rzp_test_OkYZMYKswptVZX",
          currency: result.data.currency,
          amount: result.data.amount * 100,
          order_id: result.data.id,
          name: "CityBaazar",
          description: "Test Transaction",
          image: "https://example.com/your_logo",
          handler: async function (response) {
            const list = {
              custId: customer.id,
              orderCreationId: orderId,
              razorpayPaymentId: response.razorpay_payment_id,
              razorpayOrderId: response.razorpay_order_id,
            };
            const result = await GetOrderDetails.getPaymentOrderList(list);
            if (result.data) {
              const EMPTY_CART = { cartItems: [] };
              const carts = cartItems || EMPTY_CART;
              setTimeout(async function () {
                let data = {
                  customerId: customer.id,
                  paymentmethod: result.data.method,
                  orderId: orderId,
                  deliveryAddress: deliveryAddress,
                  product: carts,
                  grandTotal: result.data.amount / 100,
                };

                let order = await GetOrderDetails.getOrderCreateByUser(
                  JSON.stringify(data)
                );
                if (order) {
                  NotificationManager.success("Successfully Ordered", "Order");

                  // this.setState({ isLoaded: false})
                  setTimeout(async function () {
                    CartHelper.emptyCart();
                  }, 1000);
                }
                window.location.href = "/order/success";
              }, 2000);
            } else {
              window.location.href = "/order/failed";
            }
            // console.log(result)
          },
          prefill: {
            name: "",
            email: "",
            phone_number: "",
          },
          notes: {
            address: "Razorpay Corporate Office",
          },
          theme: {
            color: "#3399cc",
          },
        };
        let payementObject = new window.Razorpay(options);
        payementObject.open();
      }
    } else {
      NotificationManager.error("Please! check address details", "Input Field");
    }
  };
  render() {
    const { cartItems } = this.props;

    const {
      subTotal,
      deliveryCharge,
      grandTotal,
      email,
      customer,
      paymentmethod,
      isLoaded,
    } = this.state;
    return (
      <div>
        <section className="pt-3 pb-3 page-info section-padding border-bottom bg-white single-product-header-bk">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <a href="/">
                  <strong>
                    <span class="mdi mdi-home"></span> Home
                  </strong>
                </a>{" "}
                <span class="mdi mdi-chevron-right"></span> <a>Checkout</a>
              </div>
            </div>
          </div>
        </section>

        <section className="checkout-page section-padding">
          <div style={{ alignContent: "center" }} className="container">
            {isLoaded ? <Loader /> : ""}
            <div className="row">
              <div className="col-md-8">
                <div className="card">
                  <h5 className="card-header">
                    My Cart{" "}
                    <span
                      style={{ color: "#EF78AC" }}
                      className="text-secondary float-right"
                    >
                      <p style={{ color: "#EF78AC" }}>
                        {cartItems.length} item
                      </p>
                    </span>
                  </h5>
                  {cartItems.map(
                    (row, index) => (
                      console.log(row),
                      (
                        <div
                          className="card-body pt-0 pr-0 pl-0 pb-0"
                          key={index}
                        >
                          <div className="cart-list-product">
                            <img
                              className="img-fluid"
                              src={row.Image}
                              alt="cart"
                            />
                            <span className="badge badge-success"></span>
                            <h5>{row.name}</h5>
                            <h6>
                              <strong>
                                <span className="mdi mdi-approval" /> Available
                                in
                              </strong>{" "}
                              - {row.SKU}
                            </h6>
                            <div style={{ float: "right" }}>
                              <p className="offer-price mb-0">
                                {row.qty} * {row.Price.toLocaleString()}{" "}
                                <i className="mdi mdi-tag-outline" />
                                <br />
                                <span
                                  style={{ float: "right", color: "#EF78AC" }}
                                >
                                  <h4>
                                    {" "}
                                    KES.{" "}
                                    {(row.qty * row.Price).toLocaleString()}
                                  </h4>
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      )
                    )
                  )}
                  <div className="total-checkout-group">
                    <div className="cart-total-dil">
                      {/* <h4>Sub Total</h4>
                      <span>KES. {subTotal.toLocaleString()}</span> */}
                    </div>
                    {/* <div className="cart-total-dil pt-3">
                      <h4>Delivery Charges</h4>
                      <span>KES. {deliveryCharge}</span>
                    </div> */}
                  </div>

                  <div className="main-total-cart">
                    <h2>Total</h2>
                    <span style={{ color: "#EF78AC" }}>
                      KES. {grandTotal.toLocaleString()}
                    </span>
                  </div>
                </div>
                <div className="card">
                  <button
                    style={{ width: "1000", background: "#EF78AC" }}
                    className="next-btn16 hover-btn"
                    onClick={this.handlePlaceOrder}
                  >
                    <strong>PLACE ORDER</strong>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    cartItems: state.cart.cartItems,
  }),
  { incrementToCart, decreaseToCart, removeFromCart }
)(Checkout);
